import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fill-height":""}},[_c(VRow,[_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.loading == false)?_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[(_vm.notVerified)?_c(VAlert,{staticClass:"justify-center",attrs:{"border":"top","colored-border":"","type":"warning","elevation":"2"}},[_c('h3',[_vm._v("Not Verified")]),_c('p',[_vm._v(" The customer is not logged in at BloxTech or RocketApps, so we can't verify their identity. ")])]):_vm._e(),(!_vm.notVerified)?_c(VAlert,{staticClass:"justify-center",attrs:{"border":"top","colored-border":"","type":"success","elevation":"2"}},[_c('h3',[_vm._v("Verified")]),_c('br'),_c('p',{attrs:{"id":"break"}},[_c('b',[_vm._v("Roblox ID:")]),_vm._v(" "+_vm._s(_vm.identifier)+" "),_c('br'),_c('i',[_vm._v("The contact verified this Roblox account.")]),_vm._v(" "),_c('br')]),_c(VBtn,{attrs:{"outlined":"","block":"","href":'https://www.roblox.com/users/' + _vm.identifier + '/profile',"target":"_blank","color":"success"}},[_vm._v(" Open Roblox profile ")])],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }