<template>
  <v-container fill-height>
    <v-row>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-col v-if="loading == false" cols="12" md="8" offset-md="2">
        <v-alert
          border="top"
          colored-border
          type="warning"
          elevation="2"
          class="justify-center"
          v-if="notVerified"
        >
          <h3>Not Verified</h3>
          <p>
            The customer is not logged in at BloxTech or RocketApps, so we can't
            verify their identity.
          </p>
        </v-alert>

        <v-alert
          border="top"
          colored-border
          type="success"
          elevation="2"
          class="justify-center"
          v-if="!notVerified"
        >
          <h3>Verified</h3>
          <br />
          <p id="break">
            <b>Roblox ID:</b> {{ identifier }} <br />
            <i>The contact verified this Roblox account.</i> <br />
          </p>
          <v-btn
            outlined
            block
            :href="'https://www.roblox.com/users/' + identifier + '/profile'"
            target="_blank"
            color="success"
          >
            Open Roblox profile
          </v-btn>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      loading: true,
      notVerified: true,
      identifier: "",
    };
  },
  mounted() {
    var self = this;
    window.addEventListener("message", function (event) {
      function isJsonString(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      }

      if (!isJsonString(event.data)) return;

      const eventData = JSON.parse(event.data);

      console.log(eventData.data.conversation.meta);

      if(eventData.data.conversation.meta.hmac_verified == true && eventData.data.conversation.meta.sender.identifier && eventData.data.conversation.meta.sender.identifier.length > 1){
        self.identifier = eventData.data.conversation.meta.sender.identifier;
        self.notVerified = false;
      } else {
        self.notVerified = true;
      }

      self.loading = false;
    });
  },
};
</script>

<style>
#break {
  word-break: break-all;
  white-space: normal;
}
</style>
